import React from 'react';
import { Button, Container } from 'react-bootstrap';
import styled from 'styled-components';
const HeroSection = () => {
    return (
        <Wrapper>
            <Container>
                <div className=" py-md-5 py-sm-3   text-center">
                    <h6 className="title">BECOME A PARTNER </h6>
                    <h3 className="subTitle mb-3">
                        Take your earnings to <br />
                        the Next level
                    </h3>
                    <p className="description text-secondary mx-auto ">
                        Partnering with the CS platform, you’ll access many benefits while increasing the value added to your customers.
                    </p>
                    <Button className=" partnerButton text-light"  onClick={() => window.open('https://cs.fabbuilder.com/signup')}>
                        BECOME A PARTNER <i className="bi bi-arrow-right"></i>
                    </Button>
                </div>
            </Container>
        </Wrapper>
    );
};

export default HeroSection;
const Wrapper = styled.div`
    background: url('/images/partnerProgram.png');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    .title {
        font-size: 18px;
        color: #0f8d7e;
    }
    .subTitle {
        font-family: Montserrat;
        font-size: 45px;
        font-weight: 700;
    }
    .description {
        font-size: 18px;
        font-weight: 400;
        width: 390px;
    }
    .partnerButton {
        font-size: 18px;
        background: #0f8d7e;
        font-weight: 700;
        border: 3px solid #0f8d7e;
    }
    .partnerButton:active {
        background: #0f8d7e;
    }
`;
