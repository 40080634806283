import React, { useState } from 'react';
import { Row, Col, Image, Container, Button } from 'react-bootstrap';
import styled from 'styled-components';
import LeadModal from '../../../shared/LeaadModal';
import { motion } from 'framer-motion';
const WhatsAppSupport = () => {
    const [showModal, setShowModal] = useState(false);

    return (
        <Wrapper>
            <Container>
                <Row className="justify-content-evenly mt-5 mb-5">
                    <Col sm={12} md={5} className="justify-content-center order-md-1 order-1">
                        <Button className="whatsApp_btn mt-3">WHATSAPP SUPPORT</Button>
                        <h2 className="mt-3 support_title">
                            Provide Better Customer
                            <br /> Support on WhatsApp
                        </h2>
                        <ul className="mt-4">
                            <li className="my-2">
                                <p>Deliver exceptional customer support directly on WhatsApp, connecting with customers through their preferred channels.</p>
                            </li>
                            <li className="my-2">
                                <p>Seamlessly manage customer interactions across WhatsApp and your CRM for a unified experience. </p>
                            </li>
                            <li className="my-2">
                                <p>Optimize query handling with automatic agent routing and rule-based distribution. </p>
                            </li>
                            <li className="my-2">
                                <p>Monitor and improve support efficiency with a shared team inbox and agent performance analytics. </p>
                            </li>
                        </ul>
                        <Button onClick={() => setShowModal(true)} className=" ms-2 buyNow mt-3 px-3">
                            Start Now <i className="bi bi-arrow-right mt-2 ms-1"></i>
                        </Button>
                    </Col>
                    <Col md={5} sm={12} className="order-md-2 order-2">
                        <motion.div
                            initial={{ x: '100%', opacity: 0 }}
                            whileInView={{ x: 0, opacity: 1 }}
                            viewport={{ once: true }}
                            transition={{ duration: 1 }}
                            whileHover={{ scale: 1.1 }}
                            whileTap={{ scale: 0.9 }}
                        >
                            <Image className="" src={'/images/userConverstion.svg'} fluid />
                        </motion.div>
                    </Col>
                </Row>
            </Container>
            <LeadModal title={'Start Now'} showModal={showModal} onCloseModal={() => setShowModal(false)} setShowModal={setShowModal} />
        </Wrapper>
    );
};

export default WhatsAppSupport;

const Wrapper = styled.div`
    .whatsApp_btn {
        background: #f7f6f9;
        border: none;
        color: #019498;
        padding: 10px 20px;
        border-radius: 25px;
        line-height: normal;
        font-weight: 500;
    }

    .bookDemo_btn:active {
        background: #f7f6f9;
    }
    ul {
        list-style: none;
        padding: 0;
        margin: 0;
    }

    ul li {
        margin-bottom: 10px;
        padding-left: 40px;
        background-image: url('/images/check.png');
        background-repeat: no-repeat;
        background-position: left top;
        background-size: 25px 25px;
    }
    .buyNow {
        font-size: 14px;
        color: #fff;
        border: 3px solid #012f33;
        background: #012f33;
        border-radius: 25px;
        font-weight: 600;
        &:hover {
            background: #fff;
            color: #012f33;
        }
    }
    .support_title {
        font-weight: 600;
    }
`;
