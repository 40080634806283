import React from 'react';
import { Col, Container, Row, Image } from 'react-bootstrap';
import ReasonsCard from '../../shared/ReasonsCard';
import styled from 'styled-components';
import { motion } from 'framer-motion';
const ReasonsToChoose = () => {
    return (
        <Wrapper>
            <div id="whyChooseUs" className="mt-5">
                <h1 className="text-center  fw-bold pt-4">More Reasons to Choose</h1>
                <Container className="mt-5 mb-5 justify-content-center">
                    <Image src="/images/dotGrid.png" className="mt-5 position-absolute bg_grid" />
                    <Row className="pt-5">
                        <Col md={6} sm={12}>
                            <motion.div
                                initial={{ x: '-100%', opacity: 0 }}
                                whileInView={{ x: 0, opacity: 1 }}
                                viewport={{ once: true }}
                                transition={{ duration: 1 }}
                            >
                                <ReasonsCard title={'Lead Management'} src={'/images/leadManager.svg'} link={''} />
                            </motion.div>
                        </Col>

                        <Col md={6} sm={12}>
                            <motion.div
                                initial={{ x: '100%', opacity: 0 }}
                                whileInView={{ x: 0, opacity: 1 }}
                                viewport={{ once: true }}
                                transition={{ duration: 1 }}
                            >
                                <ReasonsCard title={'Real-time Messaging'} src={'/images/realTimeMessaging.svg'} link={''} />
                            </motion.div>
                        </Col>
                        <Col md={6} sm={12}>
                            <motion.div
                                initial={{ x: '-100%', opacity: 0 }}
                                whileInView={{ x: 0, opacity: 1 }}
                                viewport={{ once: true }}
                                transition={{ duration: 1 }}
                            >
                                <ReasonsCard title={'Multi Channel Integration'} src={'/images/seamLessIntegration.svg'} link={''} />
                            </motion.div>
                        </Col>
                        <Col md={6} sm={12}>
                            <motion.div
                                initial={{ x: '100%', opacity: 0 }}
                                whileInView={{ x: 0, opacity: 1 }}
                                viewport={{ once: true }}
                                transition={{ duration: 1 }}
                            >
                                <ReasonsCard title={'Customized Template'} src={'/images/customTemplate.svg'} link={''} />
                            </motion.div>
                        </Col>
                    </Row>
                </Container>
            </div>
        </Wrapper>
    );
};

export default ReasonsToChoose;
const Wrapper = styled.div`
    .bg_grid {
        left: 50px;
    }
`;
