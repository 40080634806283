import React, { useEffect, useState } from 'react';
import { Button, Col, Container, Image, Row, Stack } from 'react-bootstrap';
import styled from 'styled-components';
import Benefits from '../LandingPage/sections/Benefits';
import CustomerSupport from '../LandingPage/sections/CustomerSupport';
import CustomizeSegment from '../LandingPage/sections/CustomizeSegment';
import EarlyBirdOffer from '../LandingPage/sections/EarlyBirdOffer';
import GetAccess from '../LandingPage/sections/GetAccess';
import GetStartedBanner from '../LandingPage/sections/GetStartedBanner';
import Support from '../LandingPage/sections/HowWeSupport';
import Integrations from '../LandingPage/sections/Integrations';
import ReasonsToChoose from '../LandingPage/sections/ReasonsToChoose';
import Testinomails from '../LandingPage/sections/Testinomails';
import WebForm from '../LandingPage/sections/WebForm';
import StandOut from './sections/StandOut';
import TeamInbox from './sections/TeamInbox';
import WhatsAppSupport from './sections/WhatsAppSupport';
import { useLocation } from 'react-router-dom';
import LeadModal from '../../shared/LeaadModal';
import { motion } from 'framer-motion';
const LandingPage = () => {
    const location = useLocation();
    const [showModal, setShowModal] = useState(false);
    const [modalTitle, setModalTitle] = useState('');
    const onOpenModal = (title) => {
        setModalTitle(title);
        setShowModal(true);
    };
    useEffect(() => {
        if (location.state && location.state.scrollToSection) {
            const sectionId = location.state.scrollToSection;
            const section = document.getElementById(sectionId);
            if (section) {
                section.scrollIntoView({ behavior: 'smooth' });
            }
        }
    }, [location.state]);

    return (
        <LandingPageWrapper>
            <h1 className="text-center fw-bold mt-5">
                Grow Your Business Faster with a Better
                <br /> WhatsApp Business API Solution
            </h1>

            <Stack className=" justify-content-center mt-4" direction="horizontal" gap={3}>
                <Button variant="outline-success" className="bookDemo_btn" onClick={() => onOpenModal('Book a Demo')}>
                    Book a Demo
                </Button>
                <Button onClick={() => onOpenModal('Try Now')} className="tryNow_btn">
                    Try Now
                </Button>
            </Stack>

            <Container>
                <motion.div whileHover={{ scale: 1.1 }} whileTap={{ scale: 0.9 }} className="text-center">
                    <Image src="/images/heroImage.svg" rounded className="my-5 d-block mx-auto" fluid />
                </motion.div>
            </Container>
            <Row className="mx-auto cs_features py-5 justify-content-evenly">
                <Col lg={2} md={6} sm={12}>
                    <div className=" w-100">
                        <motion.div whileHover={{ scale: 1.1 }} whileTap={{ scale: 0.9 }} className="text-center">
                            <Image src="/images/whatsappWorkflow.svg" fluid />
                            <p className="pt-3 description mx-auto"> Build your own WhatsApp Workflows</p>
                        </motion.div>
                    </div>
                </Col>
                <Col lg={2} md={6} sm={12}>
                    <div className=" w-100">
                        <motion.div whileHover={{ scale: 1.1 }} whileTap={{ scale: 0.9 }} className="text-center">
                            <Image src="/images/whatsappAnalytics.svg" fluid />
                            <p className="pt-3 description mx-auto">Campaign & Agent's WhatsApp Analytics</p>
                        </motion.div>
                    </div>
                </Col>
                <Col lg={2} md={6} sm={12}>
                    <div className=" w-100">
                        <motion.div whileHover={{ scale: 1.1 }} whileTap={{ scale: 0.9 }} className="text-center">
                            <Image src="/images/engagement.svg" fluid />
                            <p className="pt-3 description mx-auto"> Real-Time Engagement</p>
                        </motion.div>
                    </div>
                </Col>
                <Col lg={2} md={6} sm={12}>
                    <div className=" w-100">
                        <motion.div whileHover={{ scale: 1.1 }} whileTap={{ scale: 0.9 }} className="text-center">
                            <Image src="/images/data.svg" fluid />
                            <p className="pt-3 description mx-auto"> Own Your Data</p>
                        </motion.div>
                    </div>
                </Col>
                <Col lg={2} md={6} sm={12}>
                    <div className=" w-100">
                        <motion.div whileHover={{ scale: 1.1 }} whileTap={{ scale: 0.9 }} className="text-center">
                            <Image src="/images/inbox.svg" fluid />
                            <p className="pt-3 description mx-auto"> Shared Team Inbox</p>
                        </motion.div>
                    </div>
                </Col>
            </Row>

            <ReasonsToChoose />
            <GetAccess />
            <Integrations />
            <CustomerSupport />
            <WebForm />
            <CustomizeSegment />
            <GetStartedBanner />
            <WhatsAppSupport />
            <TeamInbox />
            <Benefits />
            <Support />
            <StandOut />
            <EarlyBirdOffer />
            <Testinomails />
            <LeadModal title={modalTitle} showModal={showModal} onCloseModal={() => setShowModal(false)} setShowModal={setShowModal} />
        </LandingPageWrapper>
    );
};

export default LandingPage;

const LandingPageWrapper = styled.div`
    font-family: Montserrat;
    overflow-x: hidden;
    .tryNow_btn {
        font-weight: 700;
        background: #012f33;
        padding: 10px 20px;
        border-radius: 100px;
        border: 3px solid #012f33;
        &:hover {
            background: #fff;
            color: #012f33;
        }
    }
    .tryNow_btn:active {
        background: #012f33;
        color: #fff;
        border: 3px solid #012f33;
    }
    .bookDemo_btn {
        color: #012f33;
        font-weight: 700;
        border: 3px solid #012f33;
        padding: 10px 20px;
        border-radius: 100px;
    }
    .bookDemo_btn:hover {
        color: #fff;
        border: 3px solid #012f33;
        background: #012f33;
    }

    h1,
    h2 {
        font-weight: 700;
    }
    .cs_features {
        background: #f6f7f9;
    }
    .description {
        font-weight: 600;
        width: 200px;
    }
`;
