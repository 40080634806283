import React from 'react';
import { Container, Image } from 'react-bootstrap';
import styled from 'styled-components';

const BecomePartner = () => {
    return (
        <Wrapper>
            <Container>
                <div>
                    <h1 className="pt-md-5 pt-sm-3 text-center title">
                        <b>Step to Become Partner</b>
                    </h1>
                    <Image src="/images/becomePartner.png" className="py-md-5 py-sm-3" fluid />
                </div>
            </Container>
        </Wrapper>
    );
};

export default BecomePartner;
const Wrapper = styled.div`
    background: #f7f8fa;
    .title {
        font-weight: 700;
        font-family: Montserrat;
    }
`;
