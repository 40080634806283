import React, { useState, useEffect } from 'react';
import { Container, Card, Accordion, Spinner } from 'react-bootstrap';

import styled from 'styled-components';
import { ahdJS } from '../../utils/ahd';

const FaqSection = () => {
    const [loading, setLoading] = useState(false);
    const [collapses, setCollapses] = useState([]);
    const [activeKey, setActiveKey] = useState(null);

    const isMounted = React.useRef(false);

    const fetchFaqs = async () => {
        try {
            setLoading(true);

            const response = await ahdJS.fetchFaqs('/faqs');
            console.log(response);
            setCollapses(response?.rows);
        } catch (err) {
            console.error(err?.response?.data);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        if (!isMounted.current) {
            fetchFaqs();
            isMounted.current = true;
        }
    }, []);

    const handleCollapseChange = (key) => {
        setActiveKey(activeKey === key ? null : key);
    };

    return (
        <Wrapper>
            <Container>
                <div>
                    <h1 className="text-center py-md-5 py-sm-3">
                        <b>Frequently asked questions</b>
                    </h1>
                    {loading && (
                        <div className="d-flex justify-content-center my-5">
                            <Spinner animation="border" />
                        </div>
                    )}
                    {!loading && (
                        <Accordion activeKey={activeKey} className="mb-5">
                            {collapses.flat().map((collapse, index) => (
                                <Card key={index} className="border-0 border-bottom bg-none my-2">
                                    <Card.Header className="border-0 bg-none rounded-0" onClick={() => handleCollapseChange(index)}>
                                        <div className={`p-2 d-flex justify-content-between align-items-center ${activeKey === index ? 'active_faq' : ''}`}>
                                            <h6 className="m-0">{`${collapse.faqs.order}. ${collapse.faqs.question}`}</h6>
                                            {activeKey === index ? <i className="bi bi-dash-lg"></i> : <i className="bi bi-plus-lg"></i>}
                                        </div>
                                    </Card.Header>
                                    <Accordion.Collapse eventKey={index}>
                                        <Card.Body>
                                            <div
                                                className="m-0 ps-3"
                                                dangerouslySetInnerHTML={{
                                                    __html: collapse.faqs.content[0]?.content
                                                }}
                                            />
                                        </Card.Body>
                                    </Accordion.Collapse>
                                </Card>
                            ))}
                        </Accordion>
                    )}
                </div>
            </Container>
        </Wrapper>
    );
};

export default FaqSection;

const Wrapper = styled.div`
    h6 {
        cursor: pointer;
        font-family: Nunito;
        font-weight: 700;
    }
    .active_faq {
        color: #0f8d7e;
    }
    .card {
        border-radius: 0px;
    }
    .card-header {
        border-radius: 0px;
        background: none;
    }
    p {
        margin: 0px;
    }
`;
