import React, { useState, useEffect } from 'react';
import { AHD_HOST } from './utils/ahd';
import { Container, Spinner } from 'react-bootstrap';
import styled from 'styled-components';

const PrivacyPolicy = () => {
    const [loading, setLoading] = useState(false);
    const [pageInfo, setPageInfo] = useState([]);

    useEffect(() => {
        const fetchPageInfo = async () => {
            setLoading(true);
            try {
                const pageRes = await fetch(`${AHD_HOST}/pagebyslug/${'privacy-policy'}`);
                const data = await pageRes.json();
                setPageInfo(data);
            } catch (err) {
                setLoading(false);
                console.log(err.response?.data || 'NO PAGE INFO FOUND FOR ');
            } finally {
                setLoading(false);
            }
        };

        fetchPageInfo();
    }, []);
    return (
        <Wrapper className=" py-3">
            <Container>
                {loading && (
                    <div className="d-flex justify-content-center my-5">
                        <Spinner animation="border" />
                    </div>
                )}
                {!loading && pageInfo?.sections?.map((section) => <div dangerouslySetInnerHTML={{ __html: section?.content }} />)}
            </Container>
        </Wrapper>
    );
};

export default PrivacyPolicy;
const Wrapper = styled.div`
    p {
        margin: 0px;
    }
`;
