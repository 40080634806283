import React, { useState } from 'react';
import { Button, Col, Image, Row } from 'react-bootstrap';
import styled from 'styled-components';
import LeadModal from '../../../shared/LeaadModal';
import { motion } from 'framer-motion';

const GetAccess = () => {
    const [showModal, setShowModal] = useState(false);
    const modalTitle = 'Get Instant Access';
    const onOpenModal = () => {
        setShowModal(true);
    };
    return (
        <motion.div initial={{ x: '100%', opacity: 0 }} whileInView={{ x: 0, opacity: 1 }} viewport={{ once: true }} transition={{ duration: 1 }}>
            <div id="pricing" className="mx-lg-5 mx-sm-1">
                <Wrapper className="mb-5">
                    <Row className="w-100">
                        <Col sm={12} md={6} className="p-0 order-md-1 order-1">
                            <Image src="/images/getAccessleft.png" className="mt-4 mt-0" fluid />
                        </Col>
                        <Col sm={12} md={6} className="d-flex justify-content-center align-items-center p-0 order-md-2 order-1">
                            <div className="text-center">
                                <p className="caption">Early Bird Offer</p>
                                <Button className="discount_btn mt-5">70% DISCOUNT</Button>
                                <h1 className=" mt-3 start_today text-light">Start Today</h1>
                                <h2 className="mt-3 text-light text-decoration-line-through">Normal Price: $1199</h2>
                                <p className="m-0 pricing">ONLY $499</p>
                                <Button className="mb-3 rounded discount_btn">ONE TIME PAYMENT | NO MONTHLY FEES</Button>
                                <br/>
                                <Button className=" mt-3 mb-5 rounded get_started" onClick={onOpenModal}>
                                    GET INSTANT ACCESS
                                </Button>
                            </div>
                        </Col>
                    </Row>
                    <LeadModal title={modalTitle} showModal={showModal} onCloseModal={() => setShowModal(false)} setShowModal={setShowModal} />
                </Wrapper>
            </div>
        </motion.div>
    );
};

export default GetAccess;

const Wrapper = styled.div`
    background: url('/images/getAccess.png');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    border-radius: 68px;
    padding: 20px;

    .discount_btn {
        background-color: #20484c;
        opacity: 1;
        color: #7dd4d4;
        border: 2px solid #7dd4d4;
        border-radius: 50px;
        padding: 10px 24px;
        font-weight: 700;
        font-size: 28px;
        &:active {
            background-color: #20484c;
            opacity: 1;
            color: #7dd4d4;
            border: 2px solid #7dd4d4;
        }
    }

    .start_today {
        font-size: 63px;
    }

    .pricing {
        font-size: 100px;
        font-weight: bold;
        background: linear-gradient(90deg, #5bb7b8 0%, #a6f9f9 53.53%, #5bb7b8 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
        color: transparent;
    }

    .get_started {
        background: #012f33;
        color: #fff;
        padding: 10px 24px;
        font-weight: 700;
        font-size: 28px;
        border: 3px solid #fff;
        &:hover {
            background: #fff;
            color: #012f33;
        }
    }

    @media (max-width: 1024px) {
        background-size: cover;
        padding: 20px;

        .start_today {
            font-size: 45px;
        }

        .pricing {
            font-size: 60px;
        }

        .discount_btn,
        .get_started {
            font-size: 22px;
            padding: 8px 16px;
        }

        .mb-5 {
            margin-bottom: 3rem;
        }
    }

    @media (max-width: 768px) {
        background-size: cover;
        padding: 20px;

        .start_today {
            font-size: 45px;
        }

        .pricing {
            font-size: 60px;
        }

        .discount_btn,
        .get_started {
            font-size: 22px;
            padding: 8px 16px;
        }

        .mb-5 {
            margin-bottom: 3rem;
        }
    }

    @media (max-width: 576px) {
        .start_today {
            font-size: 35px;
        }

        .pricing {
            font-size: 50px;
        }

        .discount_btn,
        .get_started {
            font-size: 20px;
            padding: 6px 12px;
        }
    }
`;
