import Layout from './shared/Layout';
import { BrowserRouter, Route, Routes, useLocation } from 'react-router-dom';
import LandingPage from './components/LandingPage/LandingPage';
import Header from './shared/Header';
import Footer from './shared/Footer';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import PartnerProgram from './components/PartnersProgram/PartnerProgram';
import Signup from './components/Signup';
import TermsAndConditions from './components/TermsAndConditions';
import PrivacyPolicy from './components/PrivacyPolicy';
import './shared/styles.css';
const App = () => {
    return (
   
            <BrowserRouter>
                <MainApp />
            </BrowserRouter>
      
    );
};

const MainApp = () => {
    const location = useLocation();
    const { pathname } = location;
    return (
        <Layout>
            <Header />
            <Routes>
                <Route path="/" element={<LandingPage />} />
                <Route path="/partner-program" element={<PartnerProgram />} />
                <Route path="/early-bird" element={<Signup />} />
                <Route path="/terms-conditions" element={<TermsAndConditions />} />
                <Route path="/privacy-policy" element={<PrivacyPolicy />} />
            </Routes>
            {pathname !== '/early-bird' && <Footer />}
        </Layout>
    );
};

export default App;
