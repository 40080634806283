import axios from 'axios';
import React, { useState } from 'react';
import { Card, Container, Image, Spinner } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { API_SIGNUP } from '../constants';
const Signup = () => {
    const [phoneNumber, setPhoneNumber] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');

    const [errorMsg, setErrorMsg] = useState();
    const [loading, setLoading] = useState(false);

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (password !== confirmPassword) {
            setErrorMsg('Passwords do not match');
        }
        try {
            setLoading(true);
            const response = await axios.post(API_SIGNUP, {
                phoneNumber,
                password
            });
            localStorage.setItem('jwt', response.data);
            // dispatch({ type: SET_USER, user: response.data });
            window.open('https://cs.fabbuilder.com');
        } catch (error) {
            setErrorMsg(error.response.data);
            console.log(error);
        } finally {
            setLoading(false);
        }
    };
    return (
        <Wrapper>
            <Container>
                <Image src="/images/customers.png" className=" py-md-5 py-sm-3 d-block mx-auto" fluid />
                <div className=" text-center">
                    <h1>It’s a win-win</h1>
                    <p>
                        Make customers happy and improve team <br />
                        efficiency at the same time.
                    </p>
                </div>
                <div className="py-md-5 py-sm-3 mx-auto">
                    <Card className='p-5'>
                        <form className="rounded p-3 h-100" onSubmit={handleSubmit}>
                            <div className="mb-3">
                                <label for="phoneNumber" className="form-label">
                                    Phone Number
                                </label>
                                <input
                                    type="email"
                                    value={phoneNumber}
                                    onChange={(e) => setPhoneNumber(e.target.value)}
                                    className="form-control"
                                    id="email"
                                    required
                                />
                            </div>
                            <div className="mb-3">
                                <label for="password" className="form-label">
                                    Password
                                </label>
                                <input
                                    type="password"
                                    value={password}
                                    onChange={(e) => setPassword(e.target.value)}
                                    className="form-control"
                                    id="password"
                                    required
                                />
                            </div>{' '}
                            <div className="mb-3">
                                <label for="confirmPassword" className="form-label">
                                    Confirm Password
                                </label>
                                <input
                                    type="password"
                                    value={confirmPassword}
                                    onChange={(e) => setConfirmPassword(e.target.value)}
                                    required
                                    className="form-control"
                                    id="confirmPassword"
                                />
                            </div>
                            {errorMsg && <p className="text-danger">{errorMsg}</p>}
                            <button type="submit" className="btn w-100 btn-primary">
                                {loading && <Spinner className="mr-2" style={{ width: '25px', height: '25px' }} />} Submit
                            </button>
                        </form>
                    </Card>
                </div>
                <div className="d-flex justify-content-center  my-md-5 my-sm-3">
                    <Link to={'/terms-conditions'} replace className="text-decoration-none">
                        <p className="text-secondary mx-2">Terms & Conditions</p>
                    </Link>
                    <Link to="/privacy-policy" replace className="text-decoration-none">
                        <p className="text-secondary mx-2">Privacy Policy</p>
                    </Link>
                </div>
            </Container>
        </Wrapper>
    );
};

export default Signup;

const Wrapper = styled.div`
    background: url('/images/signup.png');
    background-size: 100%;
    background-position: center;
    background-repeat: no-repeat;
`;
