import React from 'react';
import { Col, Container, Row, Card } from 'react-bootstrap';
import styled from 'styled-components';

const ParterTypes = () => {
    return (
        <Wrapper>
            <Container>
                <h1 className="mt-md-5 mt-sm-3 text-center">Our Partnership Program</h1>
                <Row xs={1} md={3} className="g-3 my-md-5 my-sm-3">
                    <Col lg={4} md={6} sm={12}>
                        <Card className="p-3 implementationParter">
                            <Card.Body className="text-center">
                                <Card.Title>Implementation Partner</Card.Title>
                                <Card.Text className="mt-3">
                                    Expand your offerings by integrating CS and a host of other powerful features to elevate your marketing, sales, and support
                                    services.
                                </Card.Text>
                                <Card.Text className="mt-3">
                                    Perfect for Business Consultants, Technology Advisors, and Market Partners looking to enhance their service portfolio.
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </Col>{' '}
                    <Col lg={4} md={6} sm={12}>
                        <Card className="p-3 affliatePartner">
                            <Card.Body className="text-center">
                                <Card.Title>Affiliate Partner</Card.Title>
                                <Card.Text className="mt-3">
                                    Spread the word about CS by sharing it on your social media, recommending it to colleagues, and helping us connect with
                                    potential customers.
                                </Card.Text>
                                <Card.Text className="mt-3">
                                    Perfect for Freelancer Consultants, Business Coaches, and Social Media Influencers looking to partner with a growing brand.{' '}
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </Col>{' '}
                    <Col lg={4} md={6} sm={12}>
                        <Card className="p-3 resellerPartner">
                            <Card.Body className="text-center">
                                <Card.Title>Reseller Partner</Card.Title>
                                <Card.Text className="mt-3">Create your own SaaS brand using CS and market and sell directly to your clients.</Card.Text>
                                <Card.Text className="mt-3">
                                    Perfect for Consulting Firms, Technology Companies, and Entrepreneurs looking to expand their offerings.
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </Wrapper>
    );
};

export default ParterTypes;
const Wrapper = styled.div`
    font-family: Montserrat;
    h1 {
        font-weight: 700;
    }
    .card-title {
        font-weight: 700;
    }
    .card {
        border-radius: 15px;
        height: 300px;
        border: none;
    }
    .implementationParter {
        background: #fff1f3;
    }
    .affliatePartner {
        background: #f8f2ff;
    }
    .resellerPartner {
        background: #f1fcff;
    }
`;
