import React, { useState } from 'react';
import { Container, Image, Button } from 'react-bootstrap';
import styled from 'styled-components';
import LeadModal from '../../../shared/LeaadModal';
import { motion } from 'framer-motion';
const Support = () => {
    const [showModal, setShowModal] = useState(false);

    return (
        <Wrapper>
            <Container className="text-center">
                <h3 className="mt-5 fw_600">
                    How We Support Agencies, Local
                    <br /> Marketers, and Entrepreneurs Like You
                </h3>
                <p>Here’s How Our Tool Makes Your Life Easier</p>
                <motion.div whileHover={{ scale: 1.1 }} whileTap={{ scale: 0.9 }}>
                    <Image src="/images/support.png" fluid />
                </motion.div>
                <div className="d-flex justify-content-center my-5">
                    <Button onClick={() => setShowModal(true)} className="tryNow_btn">
                        Try Now
                    </Button>
                </div>
            </Container>
            <LeadModal title={'Try Now'} showModal={showModal} onCloseModal={() => setShowModal(false)} setShowModal={setShowModal} />
        </Wrapper>
    );
};

export default Support;

const Wrapper = styled.div`
    .fw_600 {
        font-weight: 600;
    }
`;
