import React from 'react';
import { Row, Col, Container } from 'react-bootstrap';
import BenefitsCard from '../../shared/BenefitsCard';
import styled from 'styled-components';

const Benefits = () => {
    const benefits = [
        { title: 'Self-Hosting', description: 'Take full control with self-hosting, ensuring your data remains secure and managed entirely on your terms.' },
        { title: 'Secure Messaging', description: 'Communicate with confidence through our secure messaging feature, protecting your conversations and data.' },
        {
            title: '2-Factor Authentication',
            description: 'Enhance your security with 2-factor authentication, adding an extra layer of protection to your account.'
        },
        { title: 'Grouping', description: 'Organize your contacts effortlessly with grouping, making it easier to manage and target specific audiences.' },
        {
            title: 'Dynamic Segmentation',
            description: 'Tailor your communications with dynamic segmentation, allowing for personalized and effective customer engagement.'
        },
        { title: 'Variables', description: 'Customize your messages using variables to address each customer individually and enhance their experience.' },
        { title: 'Media Library', description: 'Access a comprehensive media library soon, making it easy to manage and utilize your content assets.' },
        {
            title: 'Multiple Channels in One',
            description: 'Streamline your communication by managing multiple channels from a single platform, improving efficiency and consistency.'
        },
        { title: 'Tagging', description: 'Simplify organization with tagging, allowing you to easily categorize and track interactions and data.' },
        { title: 'Buy Once', description: 'Enjoy the benefit of a one-time purchase with our tool, eliminating recurring fees and providing lasting value.' },
        {
            title: 'Analytics',
            description: 'Gain valuable insights with advanced analytics, helping you understand customer behavior and optimize your strategies.'
        },
        {
            title: 'Broadcast',
            description: 'Reach your audience effectively with our broadcast feature, ensuring your message gets to the right people at the right time.'
        }
    ];

    return (
        <Wrapper id='features' className="h-100">
            <h2 className="pt-4 text-center">Discover the Full Range of Benefits</h2>
            <Container>
                <Row className=" mt-5 mb-5">
                    {benefits.map((benefit, index) => (
                        <Col key={index} xs={12} sm={6} md={4} className="mb-4 ">
                            <BenefitsCard title={benefit.title} description={benefit.description} />
                        </Col>
                    ))}
                </Row>
            </Container>
        </Wrapper>
    );
};

export default Benefits;
const Wrapper = styled.div`
    background: url('/images/benefitsBackground.png');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
`;
