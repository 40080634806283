import React, { useState } from 'react';
import { Button, Card, Image } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { motion } from 'framer-motion';
import LeadModal from '../../shared/LeaadModal';
const ReasonsCard = ({ title, src, link }) => {
    const [showModal, setShowModal] = useState(false);

    const onOpenModal = () => {
        setShowModal(true);
    };
    return (
        <Wrapper>
            <Card className=" mx-3 my-4 border-0">
                <Card.Body className="Vh-100 p-4">
                    <Card.Title>
                        <div className="d-flex justify-content-between">
                            <h2 className="title_height w-50 ">{title}</h2>
                            <Link to={link}>
                                <Button className="tryNow_link" variant="link" onClick={onOpenModal}>
                                    <div className=" d-flex align-items-center">
                                        <p className="m-0">Try Now </p> <i className="bi bi-arrow-right pt-1 ms-2 icon-bold"></i>
                                    </div>
                                </Button>
                            </Link>
                        </div>
                    </Card.Title>
                    <motion.div whileHover={{ scale: 1.1 }} whileTap={{ scale: 0.9 }} className="cardImg pt-3 mt-5">
                        <Image src={src} fluid />
                    </motion.div>
                </Card.Body>
            </Card>
            <LeadModal title={'Book a Demo'} showModal={showModal} onCloseModal={() => setShowModal(false)} setShowModal={setShowModal} />
        </Wrapper>
    );
};

export default ReasonsCard;
const Wrapper = styled.div`
    h2 {
        font-size: 32px;
        font-weight: 600;
    }
    .icon-bold {
        -webkit-text-stroke: 1px;
    }
    .tryNow_link {
        text-decoration: none;
        font-size: 22px;
        color: #2d9d9f;
        font-weight: 600;
    }

    .card-body {
        background: #f7f8fa;
        border-radius: 32px;
    }
    .cardImg {
        background: #fff;
        border-radius: 38px;
        margin: 15px;
    }
    .title_height {
        height: 63px;
    }

    @media (max-width: 1024px) {
        h2 {
            font-size: 24px;
        }
        .tryNow_link {
            font-size: 18px;
        }
    }
    @media (max-width: 328px) {
        h2 {
            font-size: 14px;
        }
        .tryNow_link {
            font-size: 14px;
        }
        .title_height {
            height: auto;
        }
    }
`;
