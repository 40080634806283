import React, { useState } from 'react';
import { Container, Button } from 'react-bootstrap';
import styled from 'styled-components';
import LeadModal from '../../../shared/LeaadModal';

const GetStartedBanner = () => {
    const [showModal, setShowModal] = useState(false);

    return (
        <Wrapper className="d-flex align-items-center justify-content-center py-5">
            <Container className="text-center text-light">
                <p className="text-light font-poppins ">
                    <b>SELF HOSTED</b>
                </p>
                <h2 className="fs-1 fw-semiboldF">
                    Get The Best Self-hosted Customer <br />
                    Experience Management Tool Today!{' '}
                </h2>
                <Button onClick={() => setShowModal(true)} className="fw-bold mt-3 mb-2 get_started">
                    GET STARTED
                </Button>
                <p className="fs-5 font-normal mt-3">Enjoy full control and keep everything secure in your own space, on your own terms.</p>
            </Container>
            <LeadModal title={'Get Started'} showModal={showModal} onCloseModal={() => setShowModal(false)} setShowModal={setShowModal} />
        </Wrapper>
    );
};

export default GetStartedBanner;

const Wrapper = styled.div`
    background: url('/images/getStartedBanner.png');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    height: 500px;
    .get_started {
        background: #fff;
        color: #012f33;
        padding: 10px 24px;
        font-weight: 700;

        border-radius: 50px;
        border: 3px solid #012f33;
        &:hover {
            background: none;
            color: #fff;
            border: 3px solid #fff;
        }
    }
`;
