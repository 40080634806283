import Card from 'react-bootstrap/Card';
import styled from 'styled-components';

const BenefitsCard = ({ title, description }) => {
    return (
        <Wrapper>
            <Card className="w-100 h-100">
                <Card.Body>
                    <Card.Title>{title}</Card.Title>
                    <Card.Text>{description}</Card.Text>
                </Card.Body>
            </Card>
        </Wrapper>
    );
};

export default BenefitsCard;
const Wrapper = styled.div`
    .card {
        border-radius: 26px;
        padding: 20px 5px;
        transition: transform 300ms ease-in-out;
        &:hover {
            transform: scale(1.05);
            box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
        }
    }
    .card-title {
        font-weight: 600;
    }
`;
