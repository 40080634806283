import React, { useState } from 'react';
import { Spinner } from 'react-bootstrap';
// import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const LEAD_API = `https://invoicing.inventorylite.com/api/tenant/667919ea62ce1937dc2c9b0f`;

const ModalForm = ({ setModal2Open, title }) => {
    // const { executeRecaptcha } = useGoogleReCaptcha();
    const [loading, setLoading] = useState(false);
    const [formData, setFormData] = useState({
        fullName: '',
        email: '',
        phoneNumber: '',
        company: '',
        message: ''
    });
    const [errors, setErrors] = useState({});

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        });

        if (errors[name]) {
            setErrors({
                ...errors,
                [name]: ''
            });
        }
    };

    const handlePhoneChange = (value) => {
        setFormData({
            ...formData,
            phoneNumber: value
        });

        if (errors.phoneNumber) {
            setErrors({
                ...errors,
                phoneNumber: ''
            });
        }
    };

    const postOnServer = async (formSubmissionData) => {
        const leadUrl = `${LEAD_API}/create-lead?firstName=${formSubmissionData.fullName}&lastName=${formSubmissionData.company}&phone=${formSubmissionData.phoneNumber}&email=${formSubmissionData.email}&description=${formSubmissionData.message}&source=website`;

        try {
            setLoading(true);
            const response = await fetch(leadUrl, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json'
                }
            });

            if (response.leadNumber) {
                await toast.success('Form submitted successfully!');
                setFormData({
                    fullName: '',
                    email: '',
                    phoneNumber: '',
                    company: '',
                    message: ''
                });
                setModal2Open(false);
            } else {
                toast.error('Failed to submit the form. Please try again.');
            }
        } catch (error) {
            toast.error('An error occurred. Please try again.');
        } finally {
            setLoading(false);
        }
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        if (validateForm()) {
            setLoading(true);

            // if (!executeRecaptcha) {
            //     console.log('Execute recaptcha not yet available');
            //     return;
            // }

            // executeRecaptcha('enquiryFormSubmit').then((gReCaptchaToken) => {
            // });
            postOnServer(formData);
        }
    };

    const validateForm = () => {
        let formIsValid = true;
        const newErrors = {};

        if (formData.phoneNumber === '') {
            newErrors.phoneNumber = 'This field is required';
            formIsValid = false;
        }
        if (formData.email === '') {
            newErrors.email = 'This field is required';
            formIsValid = false;
        }
        if (formData.company === '') {
            newErrors.company = 'This field is required';
            formIsValid = false;
        }
        if (formData.message === '') {
            newErrors.message = 'This field is required';
            formIsValid = false;
        }

        if (Object.keys(newErrors).length > 0) {
            setErrors({ ...newErrors });
            return false;
        }
        setErrors({});
        return formIsValid;
    };

    return (
        <>
            <div>
                <h2 className="text-wapas-blue fs-2 font-weight-bold mb-4 text-center">{title}</h2>
            </div>
            <form onSubmit={handleSubmit} className=" p-4 rounded-lg">
                <div className="mb-3">
                    <input
                        className="form-control shadow"
                        type="text"
                        id="fullName"
                        name="fullName"
                        value={formData.fullName}
                        onChange={handleChange}
                        placeholder="Your Full Name"
                    />
                </div>
                <div className="mb-3">
                    <input
                        className={`form-control shadow ${errors?.email ? 'is-invalid' : ''}`}
                        type="email"
                        id="email"
                        name="email"
                        value={formData.email}
                        onChange={handleChange}
                        placeholder="Work Email*"
                    />
                    {errors.email && <div className="invalid-feedback">{errors.email}</div>}
                </div>

                <div className="mb-3">
                    <PhoneInput
                        country={'in'}
                        value={formData.phoneNumber}
                        onChange={handlePhoneChange}
                        inputProps={{
                            name: 'phoneNumber',
                            required: true,
                            autoFocus: true
                        }}
                        containerClass="w-100"
                        inputClass={`form-control shadow ${errors.phoneNumber ? 'is-invalid' : ''}`}
                    />
                    {errors.phoneNumber && <div className="invalid-feedback">{errors.phoneNumber}</div>}
                </div>
                <div className="mb-3">
                    <input
                        className={` shadow form-control ${errors?.company ? 'is-invalid' : ''}`}
                        type="text"
                        id="company"
                        name="company"
                        value={formData.company}
                        onChange={handleChange}
                        placeholder="Website/company/app name*"
                    />
                    {errors.company && <div className="invalid-feedback">{errors.company}</div>}
                </div>
                <div className="mb-3">
                    <textarea
                        className={`form-control shadow ${errors?.message ? 'is-invalid' : ''}`}
                        style={{ minHeight: '80px' }}
                        id="message"
                        name="message"
                        value={formData.message}
                        onChange={handleChange}
                        placeholder="Type your message here..."
                    />
                    {errors.message && <div className="invalid-feedback">{errors.message}</div>}
                </div>
                <div className="row">
                    <div className="col text-center mt-3">
                        <button type="submit" className="btn btn-primary w-100  d-flex justify-content-center align-items-center">
                            {loading && <Spinner size="sm" className="me-2 " />}
                            Submit
                        </button>
                    </div>
                </div>
            </form>
            <ToastContainer />
        </>
    );
};

export default ModalForm;
