import React from 'react';
import HeroSection from './sections/HeroSection';
import ParterTypes from './sections/ParterTypes';
import BecomePartner from './sections/BecomePartner';
import FaqSection from './sections/FaqSection';
import styled from 'styled-components';

const PartnerProgram = () => {
    return (
        <Wrapper>
            <HeroSection />
            <ParterTypes />
            <BecomePartner />
            <FaqSection />
        </Wrapper>
    );
};
export default PartnerProgram;
const Wrapper = styled.div`
    font-family: Montserrat;
`;
